import { AdPrice } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/components/AdHeading/AdPrice';
import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { useAddToFavorites } from '@domains/shared/hooks/useAddToFavorites';
import { InteractiveIsland } from '@widgets/AdvertCard/Navigation';

import { Heading } from './AdHeading.theme';

export const AdHeading = ({ ad }: { ad: AdvertItem }): JSX.Element => {
    const { addToFavoritesProps } = useAddToFavorites({ isNearbySection: false, isPromoted: false, id: ad.id });

    return (
        <Heading>
            <AdPrice ad={ad} />
            <InteractiveIsland shouldStopEventPropagation>
                <AddToFavorites {...addToFavoritesProps} placement="sellers-page" shouldUseNexusTheme />
            </InteractiveIsland>
        </Heading>
    );
};
